
@font-face {
  font-family: 'doublewidemedium';
  src: url('./assets/font/doublewidemedium-webfont.woff2') format('woff2'),
       url('./assets/font/doublewidemedium-webfont.woff') format('woff'),
       url('./assets/font/DoublewideMedium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'KapelkaNew';
    src: local('☞Kapelka New'), local('KapelkaNew'),
        url('./assets/font/KapelkaNew.woff2') format('woff2'),
        url('./assets/font/KapelkaNew.woff') format('woff'),
        url('./assets/font/KapelkaNew.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {font-family: "Kapelka-New"; src: url("//db.onlinewebfonts.com/t/47bff0b09ec9aaf6e02215c457815d87.eot"); src: url("//db.onlinewebfonts.com/t/47bff0b09ec9aaf6e02215c457815d87.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/47bff0b09ec9aaf6e02215c457815d87.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/47bff0b09ec9aaf6e02215c457815d87.woff") format("woff"), url("//db.onlinewebfonts.com/t/47bff0b09ec9aaf6e02215c457815d87.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/47bff0b09ec9aaf6e02215c457815d87.svg#Kapelka New") format("svg"); }